<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>{{shared.lemodele.value.titre}} : </ion-title>
        <ion-buttons slot="end">
         <ion-fab-button size="small" slot="start">
         <ion-icon :icon="add"></ion-icon>
</ion-fab-button>

                      <ion-icon slot="start" :ios="archiveOutline" :md="archiveSharp"></ion-icon>

       </ion-buttons>
      </ion-toolbar>
    </ion-header>

<ion-content class="ion-padding" :key="formInfo.value"><!-- :fullscreen="true" :key='formInfo.value'>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Les modules</ion-title>
        </ion-toolbar>
      </ion-header>-->
      
      <pre >{{formInfo}}</pre>
      <pre >{{shared.lemodele}}</pre>
      <ion-item v-for="champ in shared.lemodele.value.champs" :key="champ.label">
        <ion-label position="stacked">{{ champ.label }}</ion-label>
        <component :is="champ.champ.is" :v-model="formInfo.titre"></component>
      </ion-item>

<!--      <ion-item>
        <ion-label position="stacked">Stacked Label</ion-label>
        <ion-input></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Titre</ion-label>
        <ion-input v-model="formInfo.titre"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Sous-Titre</ion-label>
        <ion-textarea v-model="formInfo.soustitre" rows="4"></ion-textarea>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Date</ion-label>
        <ion-datetime display-format="MM DD YY" placeholder="Select a date" v-model="formInfo.date"></ion-datetime>
      </ion-item> -->
      <ion-item>

        <ion-button @click="sauver()">Save</ion-button>

      </ion-item>
      <!--
<ion-card button v-for="mod in shared.laListe.value" :key="mod._id" v-if="jscmodeles">


<ion-item>
      <ion-icon :icon="archiveOutline" slot="start"></ion-icon>
      <ion-card-subtitle>{{mod.nom}} </ion-card-subtitle>
          <ion-button fill="outline" slot="end" @click="() => router.push('/mods/'+mod.typeobj)">Voir push</ion-button>
    <ion-button fill="outline" slot="end" @click="shared.lister(mod.typeobj)">lister</ion-button>
    </ion-item>

    <ion-card-header>
     <ion-card-title>Gros titre</ion-card-title>
    </ion-card-header>

    <ion-card-content>
    <pre>{{mod}}</pre>
   </ion-card-content>
  </ion-card> -->
<!--
      <ion-fab vertical="top" horizontal="end" slot="fixed">
        <ion-fab-button @click="">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab> -->

   </ion-content>
  </ion-page>
</template>
<script>

import {  archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, add } from 'ionicons/icons';


import {ref, computed, watch, reactive, onMounted} from 'vue'

  import { useRoute, useRouter } from 'vue-router';
import shared from "../shared/shared";



export default {
  name: "BaseVoir",
  inject:{shared: "shared"},
  components:{
    archiveOutline, archiveSharp
  },
 props:{
  isOpen :{
    default:false,
  },
    initialValues :{
      default: {},
    }

  },
 setup(props, context){
//alert("changement")
   const router = useRouter();
   const route = useRoute();
   //alert("si j accede en direct je dois tout charger avant")
   const typeUrl = ref(route.params.type || 'modele');
//en fonction de l'url fait les bons trucs dans shared
   //alert(shared.type.value)
   //alert(typeUrl.value)
/*
   if (typeUrl.value !== shared.type.value){
     shared.lister(typeUrl.value)
   }*/

    const formInfo=reactive({
      titre:"mon titre",
      soustitre:"",
      date:""
    });
const inEditMode = computed(()=> props.initialValues? props.initialValues.id?true:false:false)

const sauver = () => {
  alert('ici');
  alert (JSON.stringify(formInfo));
};

  const handleDidDismiss= (isCancelled) => {


    if (!props.isOpen) return;

 let payload = null;
 if (isCancelled){
   payload = {
     isCancelled: true,
     formInfo: null
   };

 } else if (inEditMode.value){
   payload = {
     isCancelled: false,
     formInfo: {...formInfo, id:props.initialValues? props.initialValues.id? props.initialValues.id:null:null}
   };

 } else {
//      context.emit("modal-closed", {isCancelled, forminfo: !isCancelled?  formdata.value:null});
   delete formInfo.id;
   payload = {
     isCancelled: false,
     formInfo: {...formInfo}
   };
 }


 context.emit("modal-closed", payload);
    Object.assign(formInfo, {
      titre:"mon titre000",
      soustitre:"",
      date:""
    })

};
 watch(
     ()=> props.initialValues,
     (newVal) => {
       //alert('changement')
       //alert(JSON.stringify(newVal))
       //alert(inEditMode.value)
       if (newVal && inEditMode.value) {
         Object.assign(formInfo, props.initialValues)
         //alert(JSON.stringify(formInfo))
       }
     }
 )
return {
 //computed
  debug:shared.debug,
 inEditMode,
 //function
     handleDidDismiss,
  formInfo,
archiveOutline, archiveSharp,
route,
router,
  laListe:shared.laListe.value,
 jscmodeles:shared.jscmodeles,
add,
sauver,
//add-circle-outline,
}
}
}
</script>

<style scoped>

</style>
